.musicHeading{
    font-family: 'Candara Light';
    font-style: italic;
    font-size: 4vh;
}

.musicBody{
    font-family: 'Candara Light';
    font-size: 2vh;
  }

h3, p{
    color:white;
    margin:0%;
}

.musicContainer{
    display:flex;
    height: 20vh;
}

.left{
    width:40vw;
}
.right{
    width:50%;
}

.left, .right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}