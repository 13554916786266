
.container{
  display:flex;
  flex-direction:column;
  align-items: center;
  /* body styles */
  background: linear-gradient(-45deg, rgb(114, 108, 54)20%, rgb(91, 46, 102) 80%);
  width: calc(100vw - (100vw - 100%));
  min-height: calc(100vh);
}

h1{
    margin:auto;
  }

.section{
  margin:5%;
  display: flex;
  width:75%;
  justify-content: space-around;
}

.music.section{
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}