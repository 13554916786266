span{
    color:white;
}

img{
    width:100%;
}
.heading{
    font-family: 'Candara';
    font-style: italic;
    font-size: 4vw;
}

.body{
    font-family: 'Candara Light';
    font-size: 2vw;
  }

.Card{
    width:50%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.text{
    width:80%;
}


.slowFade{
    animation: fadeIn 4s;
}
.fastFade{
    animation: fadeIn 2s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }