
.buttonBar{
    display:flex;
    align-items: center;
    justify-content:center;
    background:rgb(24, 24, 24);
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:50px;
  }
  .buttonContainer{
    width:200px;
  }
  .buttonContainer button{
    background-color: rgb(24, 24, 24);
    font-family: 'Candara Light';
    border:none;
    width:100%;
    height:50px;
    color:white;
    font-size: 1.2rem;
  }
  .buttonContainer button:hover{
    background-color: rgb(51, 51, 51);
  }