/* width */
::-webkit-scrollbar {
    width: 14px;

  }
  
/* Track */
::-webkit-scrollbar-track {
    background: #888;
    
    width:20px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(78, 78, 78);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }